import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { QCImageSmelter } from '../QCImages/QCImageSmelter';

const CompareImageModal = ({
	imageData,
	compareImageData,
	modalId,
	imageType,
	imageUrl,
	currentCap
}) => {
	return (
		<div
			class="modal modal-xl fade"
			id={modalId}
			tabindex="-1"
			aria-labelledby={`${modalId}Label`}
			aria-hidden="true"
			style={{ marginTop: '100px' }}
		>
			<div class="modal-dialog">
				<div class="modal-content" style={{ marginLeft: '40px' }}>
					<div class="modal-header">
						<h5 class="modal-title" id={`id1Label`}>
							{imageType}
						</h5>
						<button
							type="button"
							class="btn-close"
							data-bs-dismiss="modal"
							aria-label="Close"
						></button>
					</div>

					<div class="modal-body">
						<div className="row" style={{ height: '45vh' }}>
							<div className="col">
								<p className="text-center">
									{currentCap.captured.slice(0, 10)}
								</p>
							</div>
							<div className="col">
								<p className="text-center">
									<i
										title="Capture date"
										class="fa-solid fa-calendar fa-lg me-3"
									></i>
									<i
										title="View previous date"
										class="fa-solid fa-caret-left ms-2 me-2"
									></i>
									{/* {currentCapD} */}

									<i
										title="View next date"
										class="fa-solid fa-caret-right ms-2"
									></i>
								</p>
							</div>
						</div>

						{/* true color */}
						<div className="row">
							<div className="col">
								
							</div>
							<div className="col">
								
							</div>
						</div>

						{/* ratio */}
						<div className="row">
							<div className="col">
								
							</div>
							<div className="col">
								
							</div>
						</div>

						{/* comp */}
						<div className="row">
							<div className="col">
								
							</div>
							<div className="col">
								
							</div>
						</div>
					</div>

					<div class="modal-footer">
						<button
							type="button"
							class="btn btn-secondary"
							data-bs-dismiss="modal"
						>
							Close
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CompareImageModal;
