import React, { useContext } from 'react';
import config from '../../../config';
import { Tooltip } from 'react-tooltip';
import { Row, Col } from 'reactstrap';
import Skeleton from 'react-loading-skeleton';
import { pipelineContext } from '../../../contexts/pipelineContext';
import { ClipLoader } from 'react-spinners';

const QCingFormSmelter = ({
	onQCSubmit,
	currentCap,
	imageData,
	userSelectedQCState,
	qcState,
	selectedRadio,
	setDisagreeReason,
	userHasSelected,
	skipCapture,
	imageDataError,
	loading,
	postCaptureStateSmelters
}) => {
	const { currentCaptureIndex, capsInPipeline, qcPerc } =
		useContext(pipelineContext);

	console.log(qcPerc);

	console.log(currentCap)
	console.log(userSelectedQCState)
	console.log(selectedRadio)

	return (
		<div style={{ height: '100%' }}>
			<form
				className="d-flex flex-column"
				onSubmit={(e) => onQCSubmit(e)}
				style={{ minHeight: '100%' }}
				id="QC-smelter-form"
			>
				<fieldset
					className="form-group p-3 border rounded-3 bg-white"
					style={{ minHeight: '350px' }}
				>
					<legend className="float-none w-auto px-3 my-0 fs-6">
						QC Capture
					</legend>
					<div style={{ position: 'relative', width: '100%', minHeight: '350px' }}>
						{/* no current capture */}
						{!currentCap ? (
							<Skeleton width={100} height={100} />
						) : // image data not fetched properly
						!imageData ||
						  currentCap.hasOwnProperty('newQC') ||
						  currentCap.hasOwnProperty('error') ? (
							<>
								<Row>
									{currentCap.hasOwnProperty('newQC') ? (
										<h6 className="my-2 ps-4 text-success text-center">
											Capture QC'd
										</h6>
									) : (
										<h6 className="my-2 ps-4 text-secondary">
											Select the QC state of the capture
										</h6>
									)}

									<table
										id="QCFormTable"
										className="table"
										style={{
											borderCollapse: 'separate',
											borderSpacing: '0',
											padding: '0.25rem'
										}}
									>
										<tr>
											<td>
												{currentCap.initial_state === 1 && (
													<p className="my-0 py-0" data-tooltip-id="preQCvalue">
														Pre-QC
													</p>
												)}
											</td>
											<td>
												{currentCap.initial_state === 0 && (
													<p className="my-0 py-0" data-tooltip-id="preQCvalue">
														Pre-QC
													</p>
												)}
											</td>
											<td>
												{currentCap.initial_state === -1 && (
													<p className="my-0 py-0" data-tooltip-id="preQCvalue">
														Pre-QC
													</p>
												)}
											</td>
										</tr>

										<tr>
											<td>
												<span class="r-pill__item activePill">
													{currentCap && userSelectedQCState ? (
														<>
															<input
																type="radio"
																id="qc-state-active"
																name="daily-qc-state"
																className="r-pill__input"
																value={1}
																checked={qcState === 1}
																//onClick={(e) => userHasSelected(1)}
															></input>
															<label for="qc-state-active">Active</label>
														</>
													) : (
														<>
															<input
																type="radio"
																id="qc-state-active"
																name="daily-qc-state"
																className="r-pill__input"
																value={1}
																checked={selectedRadio === 1}
																//onClick={(e) => userHasSelected(1)}
															></input>
															<label for="qc-state-active">Active</label>
														</>
													)}
												</span>
											</td>
											<td>
												<span class="r-pill__item inactivePill">
													{currentCap && userSelectedQCState ? (
														<>
															<input
																type="radio"
																id="qc-state-inactive"
																name="daily-qc-state"
																className="r-pill__input"
																value={0}
																checked={qcState === 0}
																//onClick={(e) => userHasSelected(0)}
															></input>
															<label for="qc-state-inactive">Inactive</label>
														</>
													) : (
														<>
															<input
																type="radio"
																id="qc-state-inactive"
																name="daily-qc-state"
																className="r-pill__input"
																value={0}
																checked={selectedRadio === 0}
																//onClick={(e) => userHasSelected(0)}
															></input>
															<label for="qc-state-inactive">Inactive</label>
														</>
													)}
												</span>
											</td>
											<td>
												<span class="r-pill__item invalidPill">
													{currentCap && userSelectedQCState ? (
														<>
															<input
																type="radio"
																id="qc-state-invalid"
																name="daily-qc-state"
																className="r-pill__input"
																value={-1}
																checked={qcState === -1}
																//onClick={(e) => userHasSelected(-1)}
															></input>
															<label for="qc-state-invalid">Invalid</label>
														</>
													) : (
														<>
															<input
																type="radio"
																id="qc-state-invalid"
																name="daily-qc-state"
																className="r-pill__input"
																value={-1}
																checked={selectedRadio === -1}
																//onClick={(e) => userHasSelected(-1)}
															></input>
															<label for="qc-state-invalid">Invalid</label>
														</>
													)}
												</span>
											</td>
										</tr>

										<tr>
											<td>
												{currentCap.newQC === 1 && (
													<p className="my-0 py-0" data-tooltip-id="QCvalue">
														QC
													</p>
												)}
											</td>
											<td>
												{currentCap.newQC === 0 && (
													<p className="my-0 py-0" data-tooltip-id="QCvalue">
														QC
													</p>
												)}
											</td>
											<td>
												{currentCap.newQC === -1 && (
													<p className="my-0 py-0" data-tooltip-id="QCvalue">
														QC
													</p>
												)}
											</td>
										</tr>
									</table>
									<Tooltip id="preQCvalue" place="top" effect="solid">
										Pre-QC has determined this capture to be{' '}
										{currentCap.initial_state === 1 ? (
											<span className="fw-bold activeCap">
												{
													config.qcStates.filter(
														(qstate) =>
															qstate.stateInt === currentCap.initial_state
													)[0].stateString
												}
											</span>
										) : currentCap.initial_state === 0 ? (
											<span className="fw-bold inactiveCap">
												{
													config.qcStates.filter(
														(qstate) =>
															qstate.stateInt === currentCap.initial_state
													)[0].stateString
												}
											</span>
										) : (
											<span className=" fw-bold invalidCap">
												{
													config.qcStates.filter(
														(qstate) =>
															qstate.stateInt === currentCap.initial_state
													)[0].stateString
												}
											</span>
										)}
									</Tooltip>

									{currentCap.newQC != null &&
										currentCap.newQC !== 'failed' && (
											<Tooltip id="QCvalue" place="top" effect="solid">
												You have already QC'd this image and determined the
												state to be{' '}
												<span
													className={`fw-bold ${
														currentCap.newQC === 1
															? 'activeCap'
															: currentCap.newQC === 0
															? 'inactiveCap'
															: 'invalidCap'
													}`}
												>
													{
														config.qcStates.find(
															(qstate) => qstate.stateInt === currentCap.newQC
														)?.stateString
													}
												</span>
											</Tooltip>
										)}
								</Row>
							</>
						) : (
							// all data fetched correctly
							<>
								<Row>
									<h6 className="my-2 ps-4">
										Select the QC state of the capture
									</h6>

									{currentCap.hasOwnProperty('error') && (
										<p className="text-warning">
											This capture was not QC'd properly.
										</p>
									)}

									<table
										id="QCFormTable"
										className="table"
										style={{
											borderCollapse: 'separate',
											borderSpacing: '0'
										}}
									>
										{/* pre qc values */}
										<tr>
											<td>
												{currentCap.initial_state === 1 && (
													<p className="my-0 py-0" data-tooltip-id="preQCvalue">
														Pre-QC
													</p>
												)}
											</td>
											<td>
												{currentCap.initial_state === 0 && (
													<p className="my-0 py-0" data-tooltip-id="preQCvalue">
														Pre-QC
													</p>
												)}
											</td>
											<td>
												{currentCap.initial_state === -1 && (
													<p className="my-0 py-0" data-tooltip-id="preQCvalue">
														Pre-QC
													</p>
												)}
											</td>
										</tr>

										{/* user to select QC state */}
										<tr>
											<td>
												<span class="r-pill__item activePill">
													{currentCap && userSelectedQCState ? (
														<>
															<input
																type="radio"
																id="qc-state-active"
																name="daily-qc-state"
																className="r-pill__input"
																value={1}
																checked={qcState === 1}
																onClick={(e) => userHasSelected(1)}
															></input>
															<label for="qc-state-active">Active</label>
														</>
													) : (
														<>
															<input
																type="radio"
																id="qc-state-active"
																name="daily-qc-state"
																className="r-pill__input"
																value={1}
																checked={selectedRadio === 1}
																onClick={(e) => userHasSelected(1)}
															></input>
															<label for="qc-state-active">Active</label>
														</>
													)}
												</span>
											</td>
											<td>
												<span class="r-pill__item inactivePill">
													{currentCap && userSelectedQCState ? (
														<>
															<input
																type="radio"
																id="qc-state-inactive"
																name="daily-qc-state"
																className="r-pill__input"
																value={0}
																checked={qcState === 0}
																onClick={(e) => userHasSelected(0)}
															></input>
															<label for="qc-state-inactive">Inactive</label>
														</>
													) : (
														<>
															<input
																type="radio"
																id="qc-state-inactive"
																name="daily-qc-state"
																className="r-pill__input"
																value={0}
																checked={selectedRadio === 0}
																onClick={(e) => userHasSelected(0)}
															></input>
															<label for="qc-state-inactive">Inactive</label>
														</>
													)}
												</span>
											</td>
											<td>
												<span class="r-pill__item invalidPill">
													{currentCap && userSelectedQCState ? (
														<>
															<input
																type="radio"
																id="qc-state-invalid"
																name="daily-qc-state"
																className="r-pill__input"
																value={-1}
																checked={qcState === -1}
																onClick={(e) => userHasSelected(-1)}
															></input>
															<label for="qc-state-invalid">Invalid</label>
														</>
													) : (
														<>
															<input
																type="radio"
																id="qc-state-invalid"
																name="daily-qc-state"
																className="r-pill__input"
																value={-1}
																checked={selectedRadio === -1}
																onClick={(e) => userHasSelected(-1)}
															></input>
															<label for="qc-state-invalid">Invalid</label>
														</>
													)}
												</span>
											</td>
										</tr>

										{/* user's selected state */}
										<tr>
											<td>
												{currentCap.newQC === 1 && !loading && (
													<p className="my-0 py-0" data-tooltip-id="QCvalue">
														QC
													</p>
												)}
											</td>
											<td>
												{currentCap.newQC === 0 && !loading && (
													<p className="my-0 py-0" data-tooltip-id="QCvalue">
														QC
													</p>
												)}
											</td>
											<td>
												{currentCap.newQC === -1 && !loading && (
													<p className="my-0 py-0" data-tooltip-id="QCvalue">
														QC
													</p>
												)}
											</td>
										</tr>
									</table>
									{/* TOOLTIPS */}
									<Tooltip id="preQCvalue" place="top" effect="solid">
										Pre-QC has determined this capture to be{' '}
										{currentCap.initial_state === 1 ? (
											<span className="fw-bold activeCap">
												{
													config.qcStates.filter(
														(qstate) =>
															qstate.stateInt === currentCap.initial_state
													)[0].stateString
												}
											</span>
										) : currentCap.initial_state === 0 ? (
											<span className="fw-bold inactiveCap">
												{
													config.qcStates.filter(
														(qstate) =>
															qstate.stateInt === currentCap.initial_state
													)[0].stateString
												}
											</span>
										) : (
											<span className=" fw-bold invalidCap">
												{
													config.qcStates.filter(
														(qstate) =>
															qstate.stateInt === currentCap.initial_state
													)[0].stateString
												}
											</span>
										)}
									</Tooltip>

									{currentCap.newQC != null &&
										currentCap.newQC !== 'failed' && (
											<Tooltip id="QCvalue" place="top" effect="solid">
												You have already QC'd this image and determined the
												state to be{' '}
												<span
													className={`fw-bold ${
														currentCap.newQC === 1
															? 'activeCap'
															: currentCap.newQC === 0
															? 'inactiveCap'
															: 'invalidCap'
													}`}
												>
													{
														config.qcStates.find(
															(qstate) => qstate.stateInt === currentCap.newQC
														)?.stateString
													}
												</span>
											</Tooltip>
										)}
								</Row>
							</>
						)}

						{/* DISAGREE REASON */}
						{!loading && (
							<>
								{currentCap &&
								imageData &&
								currentCap.initial_state !== qcState &&
								currentCap.initial_state !== -1 &&
								qcState === -1 ? (
									<Row className="mb-2">
										{!currentCap.hasOwnProperty('newQC') ? (
											<h6 className="my-2 ps-4">
												Select reason for disagreeing with pre-QC state
											</h6>
										) : (
											<p className="text-center text-secondary">
												Capture QC'd: reason for changing answer
											</p>
										)}

										<Col className="d-flex flex-column justify-content-center">
											<table style={{ width: 'auto', marginLeft: '25%' }}>
												<tr>
													<td>
														{' '}
														<input
															className="form-check-input qcing-extra-reason"
															type="radio"
															id="site-cloud-covered"
															name="qc-extra-questions"
															defaultChecked={true}
															//onClick={(e) => setDisagreeReason(nul)}
														></input>
													</td>
													<td>
														<label
															className="ps-2 pe-4"
															for="site-cloud-covered"
															//data-tooltip-id="QCInvalidChoice"
														>
															Site cloud covered
														</label>
													</td>
												</tr>

												<tr>
													<td>
														<input
															className="form-check-input qcing-extra-reason"
															type="radio"
															id="site-out-of-swath"
															name="qc-extra-questions"
															value={1}
															onClick={(e) => setDisagreeReason(1)}
														></input>
													</td>
													<td>
														{' '}
														<label
															className="ps-2"
															for="site-out-of-swath"
															//data-tooltip-id="QCInvalidChoice"
														>
															Site out of swath
														</label>
													</td>
												</tr>
											</table>
										</Col>
									</Row>
								) : currentCap &&
								  imageData &&
								  currentCap.initial_state !== qcState &&
								  currentCap.initial_state !== 1 &&
								  currentCap.use_smoke &&
								  qcState === 1 ? (
									<Row className="mb-2">
										{!currentCap.hasOwnProperty('newQC') ? (
											<h6 className="my-2 ps-4">
												Select reason for disagreeing with pre-QC state
											</h6>
										) : (
											<p className="text-center text-secondary">
												Capture QC'd: reason for changing answer
											</p>
										)}
										<Col className="d-flex justify-content-center">
											<div className="mb-2">
												<input
													className="form-check-input qcing-extra-reason"
													type="radio"
													id="hotspots-visible"
													name="qc-extra-questions"
													value={2}
													defaultChecked={true}
													onClick={(e) => setDisagreeReason(2)}
												></input>

												<label
													className="ps-2 pe-4"
													for="hotspots-visible"
													//data-tooltip-id="QCInvalidChoice"
												>
													Hotspots visible
												</label>

												<input
													className="form-check-input qcing-extra-reason"
													type="radio"
													id="smoke-visible"
													name="qc-extra-questions"
													value={3}
													onClick={(e) => setDisagreeReason(3)}
												></input>

												<label
													className="ps-2"
													for="smoke-visible"
													//data-tooltip-id="QCInvalidChoice"
												>
													Smoke visible
												</label>

												<input
													className="form-check-input qcing-extra-reason"
													type="radio"
													id="hotspots-smoke-visible"
													name="qc-extra-questions"
													value={4}
													onClick={(e) => setDisagreeReason(4)}
												></input>

												<label
													className="ps-2 pe-4"
													for="hotspots-smoke-visible"
													//data-tooltip-id="QCInvalidChoice"
												>
													Hotspots and smoke visible
												</label>
											</div>
										</Col>
									</Row>
								) : (
									<></>
								)}
							</>
						)}

						{currentCap && currentCap.working === 0 && selectedRadio === 1 && (
							<p className="text-warning text-center"><i className="fa-solid fa-triangle-exclamation pe-2"></i>You are setting the capture to active but the smelter is expected to be inactive. Do you want to check again?</p>
						)}

						{/* BUTTONS */}
						{currentCap && (
							<div
								className="position-absolute bottom-0 end-0"
								style={{ width: '100%'}}
							>
								<Row>
									<Col className="d-flex justify-content-end pt-1">
										<div style={{ width: '100%' }}>
											{/* if data did not arrive */}
											{imageDataError ? (
												<div className="row">
													<div className="col-6 order-2 order-lg-2  d-flex justify-content-center align-items-center"></div>
													<div className="col-6 order-3 order-lg-3  d-flex justify-content-center align-items-center">
														<button
															className="btn interactButton mt-2 mx-0 my-0"
															style={{ width: '100%' }}
															onClick={(e) => skipCapture(e)}
															data-tooltip-id="skipCapTT"
														>
															Skip
														</button>
														{currentCaptureIndex !==
															capsInPipeline.length - 1 && (
															<Tooltip
																id="skipCapTT"
																place="top"
																effect="solid"
															>
																Skip current capture, move to capture{' '}
																{currentCaptureIndex + 2}/
																{capsInPipeline.length}
															</Tooltip>
														)}

														{currentCaptureIndex ===
															capsInPipeline.length - 1 && (
															<Tooltip
																id="skipCapTT"
																place="top"
																effect="solid"
															>
																Skip current capture, move to capture 1/
																{capsInPipeline.length}
															</Tooltip>
														)}
													</div>
													<div className="col-12 order-1 d-flex flex-column align-items-center ">
														<button
															className="btn interactButtonSecondary mt-2 mx-0 my-0"
															style={{ width: '160px' }}
															disabled
														>
															{' '}
															<i class="fa-solid fa-arrow-right fa-xs ps-2"></i>
														</button>
													</div>
												</div>
											) : (
												// data fetched successfully
												<>
													<div className="row mx-0 px-0">

														{/* QC Progress */}
														<div className="col-6 order-2 order-lg-2  d-flex justify-content-center align-items-center" >
															<p className="my-0 py-0">
																<small>
																	({currentCaptureIndex + 1}/
																	{capsInPipeline.length})
																</small>{' '}
																<i
																	type="button"
																	data-bs-toggle="modal"
																	data-bs-target="#exampleModal"
																	className="interact ms-1 fa-solid fa-bars-progress fa-md"
																	id="sentinel-hub-icon"
																	data-tooltip-id="qc-progress-icon-TT"
																></i>
															</p>
															<Tooltip
																id="qc-progress-icon-TT"
																place="top"
																effect="solid"
															>
																View progress of QC
															</Tooltip>
														</div>

														{/* SKIP */}
														<div className="col-6 order-3 order-lg-3  d-flex justify-content-center align-items-center">
															{capsInPipeline.filter((cap) =>
																cap.hasOwnProperty('beenQCd')
															).length !== capsInPipeline.length ? (
																<>
																	<button
																		className="btn interactButtonSecondary mt-2 mx-0 my-0"
																		id='QCFormSmelterSkipButton'
																		onClick={(e) => skipCapture(e)}
																		data-tooltip-id="skip-cap-button"
																	>
																		Skip
																	</button>
																	<Tooltip
																		id="skip-cap-button"
																		place="top"
																		effect="solid"
																	>
																		Skip current capture, move to capture{' '}
																		{currentCaptureIndex + 2}/
																		{capsInPipeline.length}
																	</Tooltip>
																</>
															) : (
																<button
																	className="btn interactButtonSecondary mt-2 mx-0 my-0"
																	id='QCFormSmelterSkipButton'
																	style={{ width: '100%' }}
																	disabled
																>
																	Skip
																</button>
															)}
														</div>


														<div className="col-12 order-1 d-flex flex-column align-items-center ">
														{loading ? (
															// LOADING BUTTON
															<>
																<div className="col-12 order-1 order-md-3 col-md-6 d-flex flex-column align-items-center">
																	<button
																		className="btn interactButton mt-2 mx-0 my-0"
																		style={{ width: '160px' }}
																		//data-tooltip-id="captureAlreadyQCd3"
																		//data-bs-toggle="modal"
																		//data-bs-target="#moveToUploadModal"
																		disabled
																		//onClick={(e) => e.preventDefault()}
																	>
																		Loading ..{' '}
																		<ClipLoader
																			margin={3}
																			size={18}
																			color="white"
																		/>
																	</button>
																</div>
															</>

														// ERROR POSTING CAP
														) : postCaptureStateSmelters.error &&
														  currentCap.hasOwnProperty('error') ? (
															<>
																<div>
																	<button
																		className="btn interactButton mt-2 mx-0 my-0"
																		style={{ width: '160px' }}
																		data-tooltip-id="errorQCingCapture"
																		//data-bs-toggle="modal"
																		//data-bs-target="#exampleModal"
																		//onClick={(e) => e.preventDefault()}
																		onClick={(e) => skipCapture(e)}
																	>
																		<i class="fa-solid fa-arrow-right fa-xs ps-2"></i>
																	</button>
																	<Tooltip
																		id="errorQCingCapture"
																		place="top"
																		effect="solid"
																	>
																		Image not QC'd properly: move to next
																		capture
																	</Tooltip>
																</div>
															</>

														// CAPTURE IS QC'D
														) : config.qcStates
																.map((obj) => obj.stateInt)
																.includes(currentCap.newQC) ? (
															<>
																<div>
																	<button
																		className="btn interactButton mt-2 mx-0 my-0"
																		style={{ width: '160px' }}
																		data-tooltip-id="capAlreadyQCd"
																		data-bs-toggle={
																			parseInt(qcPerc) === 100 ? 'modal' : null
																		}
																		data-bs-target={
																			parseInt(qcPerc) === 100
																				? '#exampleModal'
																				: null
																		}
																		//onClick={(e) => e.preventDefault()}
																		onClick={
																			parseInt(qcPerc) === 100
																				? (e) => e.preventDefault()
																				: (e) => skipCapture(e)
																		}
																	>
																		{parseInt(qcPerc) === 100 ? (
																			<>
																				Finish QC
																				<i className="ms-2 fa-regular fa-circle-check"></i>
																			</>
																		) : (
																			<i class="fa-solid fa-arrow-right fa-xs ps-2"></i>
																		)}
																	</button>
																	<Tooltip
																		id="capAlreadyQCd"
																		place="top"
																		effect="solid"
																	>
																		Image already QC'd: move to next capture
																	</Tooltip>
																</div>
															</>
														) : (
															<>
																{/* capture QC'd and not changing */}
																{config.qcStates
																	.map((obj) => obj.stateInt)
																	.includes(currentCap.newQC) &&
																	currentCap.newQC === selectedRadio &&
																	selectedRadio !== undefined && (
																		<div>
																			{capsInPipeline.filter((cap) =>
																				cap.hasOwnProperty('beenQCd')
																			).length === capsInPipeline.length ? (
																				<>
																					<button
																						className="btn interactButton mt-2 mx-0 my-0"
																						style={{ width: '160px' }}
																						data-tooltip-id="moveToUploadTT"
																						data-bs-toggle="modal"
																						data-bs-target="#exampleModal"
																						onClick={(e) => e.preventDefault()}
																					>
																						Finish QCing{' '}
																						<i class="ms-2 fa-solid fa-circle-check"></i>
																					</button>
																					<Tooltip
																						id="moveToUploadTT"
																						place="top"
																						effect="solid"
																					>
																						review captures and move to upload
																						stage
																					</Tooltip>
																				</>
																			) : (
																				<>
																					<button
																						className="btn interactButton mt-2 mx-0 my-0"
																						style={{ width: '160px' }}
																						data-tooltip-id="captureAlreadyQCd2"
																					>
																						<i class="fa-solid fa-arrow-right fa-xs ps-2"></i>
																					</button>
																					<Tooltip
																						id="captureAlreadyQCd2"
																						place="top"
																						effect="solid"
																					>
																						capture already QC'd: no change
																					</Tooltip>
																				</>
																			)}
																		</div>
																	)}

																{/* capture QC'd and changing state */}
																{config.qcStates
																	.map((obj) => obj.stateInt)
																	.includes(currentCap.newQC) &&
																	currentCap.newQC !== selectedRadio &&
																	selectedRadio !== undefined && (
																		<div >
																			{parseInt(qcPerc) === 100 ? (
																				<button
																					className="btn interactButton mt-2 mx-0 my-0"
																					style={{ width: '160px' }}
																					data-tooltip-id="captureAlreadyQCd3"
																					data-bs-toggle="modal"
																					data-bs-target="#exampleModal"
																					onClick={(e) => e.preventDefault()}
																				>
																					<i class="fa-solid fa-arrow-right fa-xs ps-2"></i>
																				</button>
																			) : (
																				<>
																					<button
																						className="btn interactButton mt-2 mx-0 my-0"
																						style={{ width: '160px' }}
																						data-tooltip-id="captureAlreadyQCd3"
																					>
																						Set to{' '}
																						<strong>
																							{
																								config.qcStates.filter(
																									(qstate) =>
																										qstate.stateInt ===
																										selectedRadio
																								)[0].stateString
																							}
																						</strong>{' '}
																						<i class="fa-solid fa-arrow-right fa-xs ps-2"></i>
																					</button>
																					<Tooltip
																						id="captureAlreadyQCd3"
																						place="top"
																						effect="solid"
																					>
																						capture already QC'd:{' '}
																						<strong>change</strong> to{' '}
																						{selectedRadio === 1 ? (
																							<span className="fw-bold activeCap">
																								{
																									config.qcStates.filter(
																										(qstate) =>
																											qstate.stateInt ===
																											selectedRadio
																									)[0].stateString
																								}
																							</span>
																						) : selectedRadio === 0 ? (
																							<span className="fw-bold inactiveCap">
																								{
																									config.qcStates.filter(
																										(qstate) =>
																											qstate.stateInt ===
																											selectedRadio
																									)[0].stateString
																								}
																							</span>
																						) : (
																							<span className="fw-bold invalidCap">
																								{
																									config.qcStates.filter(
																										(qstate) =>
																											qstate.stateInt ===
																											selectedRadio
																									)[0].stateString
																								}
																							</span>
																						)}
																					</Tooltip>
																				</>
																			)}
																		</div>
																	)}

																{/* QC and agree */}
																{!config.qcStates
																	.map((obj) => obj.stateInt)
																	.includes(currentCap.newQC) &&
																	currentCap.initial_state === selectedRadio &&
																	selectedRadio !== undefined && (
																		<div >
																			{parseInt(qcPerc) === 100 ? (
																				<button
																					className="btn interactButton mt-2 mx-0 my-0"
																					style={{ width: '160px' }}
																					data-tooltip-id="captureAlreadyQCd3"
																					data-bs-toggle="modal"
																					data-bs-target="#exampleModal"
																					onClick={(e) => e.preventDefault()}
																				>
																					<i class="fa-solid fa-arrow-right fa-xs ps-2"></i>
																				</button>
																			) : (
																				<>
																					<button
																						className="btn interactButton mt-2 mx-0 my-0"
																						style={{ width: '160px' }}
																						data-tooltip-id="setQCagree"
																					>
																						Set to{' '}
																						<strong>
																							{
																								config.qcStates.filter(
																									(qstate) =>
																										qstate.stateInt ===
																										currentCap.initial_state
																								)[0].stateString
																							}
																						</strong>{' '}
																						<i class="fa-solid fa-arrow-right fa-xs ps-2"></i>
																					</button>
																					<Tooltip
																						id="setQCagree"
																						place="top"
																						effect="solid"
																					>
																						<strong>agree</strong> with pre-QC
																						state{' '}
																						{selectedRadio === 1 ? (
																							<span className="fw-bold activeCap">
																								{
																									config.qcStates.filter(
																										(qstate) =>
																											qstate.stateInt ===
																											selectedRadio
																									)[0].stateString
																								}
																							</span>
																						) : selectedRadio === 0 ? (
																							<span className="fw-bold inactiveCap">
																								{
																									config.qcStates.filter(
																										(qstate) =>
																											qstate.stateInt ===
																											selectedRadio
																									)[0].stateString
																								}
																							</span>
																						) : (
																							<span className="fw-bold invalidCap">
																								{
																									config.qcStates.filter(
																										(qstate) =>
																											qstate.stateInt ===
																											selectedRadio
																									)[0].stateString
																								}
																							</span>
																						)}
																					</Tooltip>
																				</>
																			)}
																		</div>
																	)}

																{/* QC and disagree */}
																{!config.qcStates
																	.map((obj) => obj.stateInt)
																	.includes(currentCap.newQC) &&
																	currentCap.initial_state !== selectedRadio &&
																	selectedRadio !== undefined && (
																		<div >
																			{parseInt(qcPerc) === 100 ? (
																				<button
																					className="btn interactButton mt-2 mx-0 my-0"
																					style={{ width: '160px' }}
																					data-tooltip-id="captureAlreadyQCd3"
																					data-bs-toggle="modal"
																					data-bs-target="#exampleModal"
																					onClick={(e) => e.preventDefault()}
																				>
																					<i class="fa-solid fa-arrow-right fa-xs ps-2"></i>
																				</button>
																			) : (
																				<>
																					<button
																						className="btn interactButton mt-2 mx-0 my-0"
																						style={{ width: '160px' }}
																						data-tooltip-id="setQCdisagree"
																					>
																						Set to{' '}
																						<strong>
																							{
																								config.qcStates.filter(
																									(qstate) =>
																										qstate.stateInt ===
																										selectedRadio
																								)[0].stateString
																							}
																						</strong>
																						<i class="fa-solid fa-arrow-right fa-xs ps-2"></i>
																					</button>
																					<Tooltip
																						id="setQCdisagree"
																						place="top"
																						effect="solid"
																					>
																						<strong>disagree</strong> with
																						pre-QC state{' '}
																						{currentCap.initial_state === 1 ? (
																							<span className="fw-bold activeCap">
																								{
																									config.qcStates.filter(
																										(qstate) =>
																											qstate.stateInt ===
																											currentCap.initial_state
																									)[0].stateString
																								}
																							</span>
																						) : currentCap.initial_state ===
																						  0 ? (
																							<span className="fw-bold inactiveCap">
																								{
																									config.qcStates.filter(
																										(qstate) =>
																											qstate.stateInt ===
																											currentCap.initial_state
																									)[0].stateString
																								}
																							</span>
																						) : (
																							<span className="fw-bold invalidCap">
																								{
																									config.qcStates.filter(
																										(qstate) =>
																											qstate.stateInt ===
																											currentCap.initial_state
																									)[0].stateString
																								}
																							</span>
																						)}
																					</Tooltip>
																				</>
																			)}
																		</div>
																	)}
															</>
														)}</div>
													</div>
												</>
											)}
										</div>
									</Col>{' '}
								</Row>
							</div>
						)}
					</div>
				</fieldset>
			</form>
		</div>
	);
};

export default QCingFormSmelter;
