import React, { useContext, useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { Tooltip } from 'react-tooltip';
import Skeleton from 'react-loading-skeleton';
import config from '../../../config';

import sentinelHubLogo from '../../../images/SHlogo.svg';

// helper funcs
import { getSentinelHubUrl } from '../../../helpers/leafletHelpers';
import formatDate from '../../../helpers/formatDate';
import comparingImagesData from '../../../helpers/comparingImagesData';

// services
import { pipelineContext } from '../../../contexts/pipelineContext';
import useGainsQCApi from '../../../services/useGainsQCApi';

// child comps
import Barplot from '../Plots/Barplot';
import HotspotsProfile from '../Plots/HotspotsProfile';
import PixelCountPlot from '../Plots/PixelCountPlot';
import QCingFormSmelter from '../Forms/QCingFormSmelter';
import QCingFormStockpiles from '../Forms/QCingFormStockpiles';
import CompareImageModal from '../Modals/CompareImageModal';
import StockpilesGeojsonsList from '../StockpilesGeojsonsList';
import { QCImageSmelter } from '../QCImages/QCImageSmelter';
import { QCImageStockpiles } from '../QCImages/QCImageStockpiles';

const QCing = ({
	currentCapture,
	currentCaptureIndex,
	qcState,
	setQCState,
	onQCSubmit,
	onStockpilesQCSubmit,
	setDisagreeReason,
	editedGeojson,
	setEditedGeojson,
	featureCollection,
	setFeatureCollection,
	featureLayers,
	setFeatureLayers,
	stockpilesFormValue,
	setStockpilesFormValue,
	loading,
	setLoading,
	postCaptureStateSmelters,
	qcTimings,
	setQCTimings,
	qcingTimerRef
}) => {
	const pipelineContextQCing = useContext(pipelineContext);

	const stockpilesCase = 2;


	const [generalError, setGeneralError] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);
	const [selectedRadio, setSelectedRadio] = useState();
	const [userSelectedQCState, setUserSelectedQCState] = useState(false);
	const [sentinelUrl, setSentinelUrl] = useState('#');
	const [drawnItemsValid, setDrawnItemsValid] = useState(true);
	const [stockpilesGeojsons, setStockpilesGeojsons] = useState();
	const [activeStockpile, setActiveStockpile] = useState(null);
	const [selectedMapView, setSelectedMapView] = useState(null);
	const [compareImageIndex, setCompareImageIndex] = useState(null);

	const {
		data: imageData2,
		loading: loadingImageData,
		error: errorImageData,
		makeRequest: makeRequestImageData,
		resetData: resetImageData
	} = useGainsQCApi();

	const {
		data: plotData2,
		loading: loadingPlotData,
		error: errorPlotData,
		makeRequest: makeRequestPlotData,
		resetData: resetPlotData
	} = useGainsQCApi();

	const compareImageData = useGainsQCApi();
	const fetchImageComparisonList = useGainsQCApi();

	const {
		data: hostpotsData,
		loading: loadingHotspotsData,
		error: errorHotspotsData,
		makeRequest: makeRequestHotspotsData,
		resetData: resetHotspotsData,
		overrideData: overrideHotspotsData
	} = useGainsQCApi();

	const {
		data: pixelPlotData2,
		loading: loadingPixelPlotData,
		error: errorPixelPlotData,
		makeRequest: makeRequestPixelPlotData,
		resetData: resetPixelPlotData
	} = useGainsQCApi();

	const fetchImageData = async () => {
		if (pipelineContextQCing.comm === 'stockpiles') {
			await makeRequestImageData(
				`${config.baseUrl}/${pipelineContextQCing.comm}/capture_image?stacItemId=${currentCapture.stac_item_id}&sensor=${currentCapture.stac_collection}&siteId=${currentCapture.site_id}&siteName=${currentCapture.name}`,
				'GET',
				null,
				`capture ${currentCapture.id}`
			);
		} else if (pipelineContextQCing.comm === 'smelters') {
			await makeRequestImageData(
				`${config.baseUrl}/${pipelineContextQCing.comm}/capture_image?stacItemId=${currentCapture.stac_item_id}&sensor=${currentCapture.sensor}&siteId=${currentCapture.site_id}`,
				// `${config.baseUrl}/${pipelineContextQCing.comm}/capture_image?`,
				'GET',
				null,
				pipelineContextQCing.notificationsEnabled
					? (currentCaptureIndex = 0 ? 'first capture..' : 'next capture..')
					: null
			);
		}
	};

	function findNearestPreviousIndex(date, list) {
		const targetDate = new Date(date).getTime(); // Convert given date to timestamp
		console.log(targetDate);

		return list.reduce((nearestIndex, obj, index) => {
			const objDate = new Date(obj.captured).getTime();

			// Only consider dates before the target date
			if (objDate < targetDate) {
				const nearestDate = new Date(
					list[nearestIndex]?.captured || 0
				).getTime();
				if (nearestDate < objDate || nearestIndex === -1) {
					return index; // Update the nearest previous index
				}
			}

			return nearestIndex;
		}, -1);
	}

	useEffect(() => {
		/**
		 * on mount, fetch image data
		 * reset form
		 * on unmount, remove image data
		 */

		setUserSelectedQCState(false);
		setStockpilesFormValue(1);
		setStockpilesGeojsons(null);
		fetchImageData();

		const index = findNearestPreviousIndex(
			currentCapture.captured,
			comparingImagesData
		);
		setCompareImageIndex(index);

		return () => {
			resetImageData();
			resetPlotData();
			resetHotspotsData();
			resetPixelPlotData();
		};
	}, [currentCapture]);

	useEffect(() => {
		if (
			errorHotspotsData ||
			errorHotspotsData ||
			errorPixelPlotData ||
			errorPlotData
		) {
			setGeneralError(true);
		} else {
			setGeneralError(false);
		}
	}, [imageData2, plotData2, hostpotsData, pixelPlotData2]);

	const fetchHotspotsData = async () => {
		await makeRequestHotspotsData(
			`${config.baseUrl}/${pipelineContextQCing.comm}/hotspot_profile?sensor=${currentCapture.sensor}&stacItemId=${currentCapture.stac_item_id}&site_id=${currentCapture.site_id}`,
			'GET',
			null,
			''
		);
	};

	const fetchBarplotData = async () => {
		await makeRequestPlotData(
			`${config.baseUrl}/${pipelineContextQCing.comm}/barplot?siteId=${
				currentCapture.site_id
			}&date=${currentCapture.captured.slice(0, 10)}&rawData=true`,
			'GET',
			null,
			''
		);
	};

	// useEffect(() => {
	// 	if (compareImageIndex){
	// 		fetchCompImageData(
	// 			comparingImagesData[compareImageIndex].stac_item_id,
	// 			currentCapture.sensor
	// 		);
	// 	}
		
	// }, [compareImageIndex]);

	// const fetchCompImageData = async (stac_item_id, sensor) => {
	// 	let url = `${config.baseUrl}/${pipelineContextQCing.comm}/capture_image?stacItemId=${stac_item_id}&sensor=${sensor}&siteId=${currentCapture.site_id}`;
	// 	await compareImageData.makeRequest(url, 'GET', null, '');
	// };

	useEffect(() => {
		/**
		 * once imageData has been fetched for capture
		 * fetch additional data (historic plot, hotspots profile, pixel plot)
		 */

		if (imageData2) {
			setLoading(false);
			if (pipelineContextQCing.comm === 'smelters') {
				fetchBarplotData();

				if (currentCapture.hotspots > 0) {
					fetchHotspotsData();
				} else {
					overrideHotspotsData([]);
				}

				if (imageData2.hasOwnProperty('data')) {
					setSentinelUrl(getSentinelHubUrl(imageData2.data, currentCapture));
				}

				// if/not capture has already been QC'd
				if (currentCapture) {
					if (currentCapture.hasOwnProperty('newQC')) {
						setSelectedRadio(currentCapture.newQC);
						setQCState(currentCapture.newQC);
					} else {
						setSelectedRadio(currentCapture.initial_state);
						setQCState(currentCapture.initial_state);
					}
				}
			} else if (pipelineContextQCing.comm === 'stockpiles') {
				makeRequestPixelPlotData(
					`${config.baseUrl}/${pipelineContextQCing.comm}/pixel_count?siteId=${currentCapture.site_id}`,
					'GET',
					null,
					'pixel plot data'
				);

				// setStockpilesGeojsons({
				// 	0: {
				// 		original: imageData2.data.hasOwnProperty('stockpiles')
				// 			? JSON.parse(imageData2.data.stockpiles).features
				// 			: [],
				// 		edited: [],
				// 		deleted: [],
				// 		created: [],
				// 		simplified: []
				// 	}
				// });
				setStockpilesGeojsons([
					imageData2.data.hasOwnProperty('stockpiles')
						? JSON.parse(imageData2.data.stockpiles).features
						: []
				]);
			}
		}
	}, [imageData2]);

	const userHasSelected = (selectedState) => {
		setUserSelectedQCState(true);
		setQCState(selectedState);
		setSelectedRadio(selectedState);
	};

	const skipCapture = (e) => {
		/**
		 * if user skips capture, move to next
		 * if capture is last capture, jump to first capture not QC'd
		 */

		e.preventDefault();

		if (
			pipelineContextQCing.currentCaptureIndex !==
			pipelineContextQCing.capsInPipeline.length - 1
		) {
			pipelineContextQCing.setCurrentCaptureIndex(
				pipelineContextQCing.currentCaptureIndex + 1
			);
		} else {
			pipelineContextQCing.setCurrentCaptureIndex(0);
		}
	};

	const copyAOIToClipboard = () => {
		if (
			imageData2 &&
			imageData2.hasOwnProperty('data') &&
			imageData2.data.hasOwnProperty('aoi')
		) {
			const text = imageData2.data.aoi;
			navigator.clipboard
				.writeText(text)
				.then(() => {
					alert('Copied AOI to clipboard');
				})
				.catch((err) => {
					alert('Couldnt copy AOI to clipboard');
				});
		}
	};


	if (pipelineContextQCing.comm === 'smelters') {
		return (
			<>
				<div className="px-1 mx-1 mx-md-3" style={{ cursor: 'default' }}>
					<hr></hr>

					{currentCapture && imageData2 && (
						<>
							<CompareImageModal
								imageData={imageData2}
								imageType="True Colour"
								imageUrl={imageData2.true_color}
								modalId={'id1'}
								compareImageData={comparingImagesData[0]}
								currentCap={currentCapture}
							/>
							{/* <CompareImageModal
								imageData={imageData2}
								imageType="Ratio"
								imageUrl={imageData2.ratio}
								modalId={'id2'}
								currentCap={currentCapture}
							/>
							<CompareImageModal
								imageData={imageData2}
								imageType="Composite"
								imageUrl={imageData2.comp}
								modalId={'id3'}
								currentCap={currentCapture}
							/> */}
						</>
					)}

					<div id="viewer" />

					<div className="row">
						<div className="col-12 col-md-6 order-md-1 d-flex justify-content-start align-items-center">
							{/* <button
								className="btn interactButtonSecondary backButton"
								//onClick={handleBackButton}
								style={{ width: '200px' }}
							>
								<i class="fa-solid fa-left-long pe-3"></i>
								Change QC type
							</button> */}
							<h1 className="mt-2">QC</h1>
							<h4 className="ms-4 mt-2">Perform QC for capture</h4>
						</div>
						<div className="col-12 "></div>
						<div className="col-12 col-md-6 order-md-2 d-flex flex-column justify-content-center align-items-end">
							{errorImageData && (
								<>
									<h5 className="text-warning">
										<i className="fa-solid fa-triangle-exclamation"></i> Error
										fetching image
									</h5>
									<p className="text-warning py-0 my-0">
										You can try to{' '}
										<span
											className="fw-bold"
											onClick={() => fetchImageData()}
											style={{ cursor: 'pointer' }}
										>
											re-fetch the image{' '}
											<i
												id="captureImageHover"
												className="fa-solid fa-arrows-rotate ms-1"
											></i>
										</span>
										, skip the capture{' '}
										<button
											className="btn btn-sm interactButton"
											onClick={(e) => skipCapture(e)}
										>
											Skip{' '}
										</button>{' '}
									</p>
								</>
							)}
							{errorPlotData && (
								<h5 className="text-warning">
									<i className="fa-solid fa-triangle-exclamation"></i> Error
									fetching historic data
								</h5>
							)}
							{errorHotspotsData && (
								<h5 className="text-warning">
									<i className="fa-solid fa-triangle-exclamation"></i> Error
									fetching hotspots data
								</h5>
							)}
							{postCaptureStateSmelters.error &&
								currentCapture &&
								currentCapture.hasOwnProperty('error') && (
									<h5 className="text-warning">
										<i className="fa-solid fa-triangle-exclamation"></i> Error
										QCing capture: skip to next capture
									</h5>
								)}
						</div>
					</div>

					{/* SITE/CAPTURE INFO */}
					<div
						className="row px-0 px-lg-2 pt-2"
						id="QCIng-info-row"
						style={{ minHeight: '5vh' }}
					>
						<div className="col-12 col-md-6 col-xl-3 d-flex justify-content-center justify-content-md-start">
							<p className="py-0 my-0" data-tooltip-id="QCingSiteInfo">
								<i class="fa-solid fa-location-dot me-2"></i>
								{!currentCapture ? (
									<Skeleton width={80} />
								) : currentCapture.site_name.length < 30 ? (
									`${currentCapture.site_id} - ${currentCapture.site_name}`
								) : (
									`${currentCapture.site_id} - ${currentCapture.site_name.slice(
										0,
										30
									)}...`
								)}
							</p>
							<Tooltip id="QCingSiteInfo" place="top" effect="solid">
								Site id - Site name <br />
								{currentCapture && currentCapture.site_name}
							</Tooltip>
						</div>
						<div className="col-12 col-md-6 col-xl-3 d-flex justify-content-center justify-content-md-end justify-content-xl-center">
							<Row>
								<div className="d-flex justify-content-end">
									<p
										className="text-capitalize ps-md-4 py-0 my-0"
										data-tooltip-id="QCingCaptureInfo"
									>
										<i className="fa-regular fa-image  me-2"></i>
										{!currentCapture ? (
											<Skeleton width={80} />
										) : (
											`${currentCapture.id} - ${currentCapture.sensor}`
										)}
									</p>
									<Tooltip id="QCingCaptureInfo" place="top" effect="solid">
										Capture id - Sensor
									</Tooltip>
								</div>
							</Row>
						</div>
						<div className="col-12 col-md-6 col-xl-3 d-flex justify-content-center justify-content-md-start justify-content-xl-center">
							<div className="row">
								<div className="col d-flex justify-content-end">
									<p
										className="text-capitalize ps-lg-4 py-0 my-0"
										data-tooltip-id="QCingCaptureDate"
									>
										<i class="fa-solid fa-calendar-day me-2"></i>
										{!currentCapture ? (
											<Skeleton width={80} />
										) : (
											`${
												currentCapture.captured
													? formatDate(currentCapture.captured.slice(0, 10))
													: '-'
											}`
										)}
									</p>

									{sentinelUrl === '#' || sentinelUrl === undefined ? (
										<a className="ms-2">
											<img
												src={sentinelHubLogo}
												alt=""
												width="25"
												height="25"
												className="rounded-circle ms-1 me-3"
												data-tooltip-id="link-to-sentinel-hub-disabled"
											></img>
										</a>
									) : (
										<a
											href={sentinelUrl}
											target="_blank"
											rel="noreferrer"
											className="ms-2 mb-1"
										>
											<img
												src={sentinelHubLogo}
												alt=""
												width="25"
												height="25"
												className="rounded-circle ms-1 me-3"
												id="sentinel-hub-icon"
												data-tooltip-id="link-to-sentinel-hub"
											></img>
										</a>
									)}

									<p>
										<i
											className="fa-solid fa-draw-polygon fa-md"
											data-tooltip-id="copyAOIToClipboard"
											onClick={
												currentCapture && imageData2
													? () => copyAOIToClipboard()
													: null
											}
										></i>
									</p>

									<Tooltip id="copyAOIToClipboard" place="top" effect="solid">
										Click to copy AOI to clipboard
									</Tooltip>
									<Tooltip id="QCingCaptureDate" place="top" effect="solid">
										Capture date
									</Tooltip>
									<Tooltip id="link-to-sentinel-hub" place="top" effect="solid">
										View capture on Sentinel Hub
									</Tooltip>
									<Tooltip
										id="link-to-sentinel-hub-disabled"
										place="top"
										effect="solid"
									>
										Link to Sentinel Hub not available until image data is
										fetched successfully
									</Tooltip>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-6 col-xl-3 d-flex justify-content-center justify-content-md-end justify-content-xl-end">
							<p
								className="mx-3 py-0 my-0"
								data-tooltip-id="QCingCloudFracInfo"
							>
								<i class="fa-solid fa-cloud pe-1"></i>
								{!currentCapture ? (
									<Skeleton width={30} />
								) : currentCapture.cloud_frac == null ? (
									'-'
								) : currentCapture.cloud_frac === 0 ? (
									0
								) : (
									currentCapture.cloud_frac.toFixed(2)
								)}
							</p>
							<Tooltip id="QCingCloudFracInfo" place="top" effect="solid">
								{currentCapture &&
									`Cloud fraction - ${
										currentCapture.cloud_frac == null
											? '-'
											: currentCapture.cloud_frac === 0
											? 0
											: currentCapture.cloud_frac.toFixed(2)
									}`}
							</Tooltip>

							<p className="mx-3 py-0 my-0" data-tooltip-id="QCingNoHotspots">
								<i class="fa-solid fa-fire pe-1"></i>
								{!currentCapture ? (
									<Skeleton width={30} />
								) : (
									currentCapture.hotspots
								)}
							</p>
							<Tooltip id="QCingNoHotspots" place="top" effect="solid">
								{currentCapture &&
									`Number of hotspots - ${currentCapture.hotspots}`}
							</Tooltip>

							<p
								className="mx-3 py-0 my-0"
								data-tooltip-id="QCingValidDataFracInfo"
							>
								<i class="fa-solid fa-chart-line pe-1"></i>
								{!currentCapture ? (
									<Skeleton width={30} />
								) : currentCapture.invalid_data_frac == null ? (
									'-'
								) : currentCapture.invalid_data_frac === 0 ? (
									0
								) : (
									currentCapture.invalid_data_frac.toFixed(2)
								)}
							</p>
							<Tooltip id="QCingValidDataFracInfo" place="top" effect="solid">
								{currentCapture &&
									`Invalid data fraction - ${
										currentCapture.invalid_data_frac == null
											? '-'
											: currentCapture.invalid_data_frac === 0
											? 0
											: currentCapture.invalid_data_frac.toFixed(2)
									}`}
							</Tooltip>

							<p className="mx-3 py-0 my-0" data-tooltip-id="QCingMBRInfo">
								<i class="fa-solid fa-signal pe-1"></i>
								{!currentCapture ? (
									<Skeleton width={30} />
								) : currentCapture.max_band_ratio == null ? (
									'-'
								) : currentCapture.max_band_ratio === 0 ? (
									0
								) : (
									currentCapture.max_band_ratio.toFixed(2)
								)}
							</p>
							<Tooltip id="QCingMBRInfo" place="top" effect="solid">
								{currentCapture &&
									`Max band ratio - ${
										currentCapture.max_band_ratio == null
											? '-'
											: currentCapture.max_band_ratio === 0
											? 0
											: currentCapture.max_band_ratio.toFixed(2)
									}`}
							</Tooltip>

							{!currentCapture ? (
								<>
									<p className="mx-3 py-0 my-0">
										<i class="fa-solid fa-volcano"></i> <Skeleton width={30} />
									</p>
									<Tooltip id="QCingUseSmokeYes" place="top" effect="solid">
										Use smoke (yes)
									</Tooltip>
								</>
							) : (
								<>
									<p className="mx-3">
										{currentCapture.use_smoke === 1 ? (
											<i
												class="fa-solid fa-volcano"
												data-tooltip-id="QCingUseSmokeYes"
											></i>
										) : (
											<div style={{ position: 'relative' }}>
												<div style={{ position: 'absolute' }}>
													<i
														class="fa-solid fa-volcano"
														data-tooltip-id="QCingUseSmokeNo"
													></i>
												</div>
												<div style={{ position: 'absolute' }}>
													<i
														class="fa-solid fa-slash"
														data-tooltip-id="QCingUseSmokeNo"
													></i>
												</div>
											</div>
										)}
									</p>
									<Tooltip id="QCingUseSmokeNo" place="top" effect="solid">
										Use smoke (no)
									</Tooltip>
								</>
							)}
						</div>
					</div>

					{/* IMAGES */}
					<div
						className="row mt-2"
						id="QCing-images-row"
						style={{ minHeight: '40vh' }}
					>
						<div className="col-12 my-2 my-lg-0 col-lg-4 d-flex justify-content-center">
							{!imageData2 || loading ? (
								<div style={{ width: '100%' }}>
									<Skeleton
										height={'400px'}
										data-tooltip-id="imageTrueColour"
									/>
								</div>
							) : (
								<div
									style={{ width: '100%' }}
									data-bs-toggle="modal"
									data-bs-target="#id1"
									onClick={() => setModalOpen(true)}
									data-tooltip-id="imageTrueColour"
								>
									<QCImageSmelter
										imageData={imageData2}
										modalId={'id1'}
										imageUrl={imageData2.data.true_color}
										openModal={modalOpen}
									/>
								</div>
							)}
							<Tooltip id="imageTrueColour" place="top" effect="solid">
								True colour
							</Tooltip>
						</div>
						<div className="col-12 my-2 my-lg-0 col-lg-4 d-flex justify-content-center">
							{!imageData2 || loading ? (
								<div style={{ width: '100%' }}>
									<Skeleton height={'400px'} data-tooltip-id="imageRatio" />
								</div>
							) : (
								<div
									style={{ width: '100%' }}
									data-bs-toggle="modal"
									data-bs-target="#id2"
									data-tooltip-id="imageRatio"
								>
									<QCImageSmelter
										modalId={'id2'}
										imageUrl={imageData2.data.ratio}
										imageData={imageData2}
										openModal={true}
									/>
								</div>
							)}
							{currentCapture && currentCapture.sensor === 'sentinel-2-l1c' ? (
								<Tooltip id="imageRatio" place="top" effect="solid">
									B12/B11
								</Tooltip>
							) : (
								<Tooltip id="imageRatio" place="top" effect="solid">
									B07/B06
								</Tooltip>
							)}
						</div>
						<div className="col-12 my-2 my-lg-0 col-lg-4 d-flex justify-content-center">
							{!imageData2 || loading ? (
								<div style={{ width: '100%' }}>
									<Skeleton height={'400px'} data-tooltip-id="imageComp" />
								</div>
							) : (
								<div
									style={{ width: '100%' }}
									data-bs-toggle="modal"
									data-bs-target="#id3"
									data-tooltip-id="imageComp"
								>
									<QCImageSmelter
										modalId={'id3'}
										imageData={imageData2}
										imageUrl={imageData2.data.comp}
										openModal={true}
									/>
								</div>
							)}
							<Tooltip id="imageComp" place="top" effect="solid">
								Thermal Composite
							</Tooltip>
						</div>
					</div>

					{/* IMAGE ERRORS */}
					<div className="row">
						<div className="col">
							{errorImageData ? (
								<h6
									style={{ cursor: 'pointer' }}
									data-tooltip-id="imageErrorWarning"
									className="text-center text-warning mt-2"
									onClick={() => fetchImageData()}
								>
									<i className="fa-solid fa-arrows-rotate me-2"></i>True Colour
								</h6>
							) : (
								<h6 className="text-center mt-2">True Colour</h6>
							)}
							<Tooltip id="imageErrorWarning" place="top" effect="solid">
								Images not fetched successfully; click to re-fetch all images
							</Tooltip>
						</div>
						<div className="col">
							{errorImageData ? (
								<h6
									style={{ cursor: 'pointer' }}
									data-tooltip-id="imageErrorWarning"
									className="text-center text-warning mt-2"
									onClick={() => fetchImageData()}
								>
									<i className="fa-solid fa-arrows-rotate me-2"></i>
									{currentCapture && currentCapture.sensor === 'sentinel-2-l1c'
										? 'B12/B11'
										: 'B07/B06'}
								</h6>
							) : (
								<h6 className="text-center mt-2">
									{currentCapture && currentCapture.sensor === 'sentinel-2-l1c'
										? 'B12/B11'
										: 'B07/B06'}
								</h6>
							)}
							<Tooltip id="imageErrorWarning" place="top" effect="solid">
								Images not fetched successfully; click to re-fetch all images
							</Tooltip>

							{/* {currentCapture && currentCapture.sensor === 'sentinel-2-l1c' ? (
								<h6 className="text-center mt-2">B12/B11</h6>
							) : (
								<h6 className="text-center mt-2">B07/B06</h6>
							)} */}
						</div>
						<div className="col">
							{errorImageData ? (
								<h6
									style={{ cursor: 'pointer' }}
									data-tooltip-id="imageErrorWarning"
									className="text-center text-warning mt-2"
									onClick={() => fetchImageData()}
								>
									<i className="fa-solid fa-arrows-rotate me-2"></i>Thermal
									Composite
								</h6>
							) : (
								<h6 className="text-center mt-2">Thermal Composite</h6>
							)}
							<Tooltip id="imageErrorWarning" place="top" effect="solid">
								Images not fetched successfully; click to re-fetch all images
							</Tooltip>
						</div>
					</div>

					{/* BARPLOT - HOTSPOTS - LAST SENSOR STATE - QC FORM */}
					<div
						className="row mt-1"
						id="QCing-barplot-hotspots-form-row"
						style={{ minHeight: '35vh' }}
					>
						<div className="col-12 order-3 col-md-12 order-lg-3 col-xxl-6 order-xxl-1 mt-3">
							<Barplot
								imageData={imageData2 ? true : false}
								plotData={plotData2}
								captureDate={currentCapture ? currentCapture.captured : null}
								plotDataError={errorPlotData}
								plotDataLoading={loadingPlotData}
								fetchBarplotData={fetchBarplotData}
								loading={loading}
							/>
						</div>
						<div className="col-12 order-2 col-md-6 order-lg-2 col-xxl-3 order-xxl-2 mt-3">
							<HotspotsProfile
								imageData={imageData2 ? true : false}
								currentCapture={currentCapture}
								hotspotsProfile={hostpotsData}
								fetchHotspotsData={fetchHotspotsData}
								hotspotsError={errorHotspotsData}
								hotspotsLoading={loadingHotspotsData}
								loading={loading}
							/>
						</div>
						<div className="col-12 order-1 col-md-6 order-lg-1  col-xxl-3 order-xxl-3 mt-3">
							<QCingFormSmelter
								loading={loading}
								onQCSubmit={onQCSubmit}
								currentCap={currentCapture}
								imageData={imageData2}
								userSelectedQCState={userSelectedQCState}
								qcState={qcState}
								selectedRadio={selectedRadio}
								setDisagreeReason={setDisagreeReason}
								userHasSelected={userHasSelected}
								skipCapture={skipCapture}
								imageDataError={errorImageData}
								imageDataLoading={loadingImageData}
								postCaptureStateSmelters={postCaptureStateSmelters}
							/>
						</div>
					</div>
				</div>
			</>
		);
	} else if (pipelineContextQCing.comm === 'stockpiles') {
		return (
			<>
				<div style={{ paddingX: '20px' }}>
					<hr></hr>
					<div className="row px-0 px-md-5 pb-2">
						<div className="col-12 col-md-4 d-flex justify-content-center">
							<p className="py-0 my-0" data-tooltip-id="QCingSiteInfo">
								<i class="fa-solid fa-location-dot me-2"></i>
								{!currentCapture ? (
									<Skeleton width={80} />
								) : (
									`${currentCapture.site_id} - ${currentCapture.site_name}`
								)}
							</p>
							<Tooltip id="QCingSiteInfo" place="top" effect="solid">
								Site id - Site name
							</Tooltip>
						</div>
						<div className="col-12 col-md-4 d-flex justify-content-center">
							<Row>
								<div className="d-flex justify-content-end">
									<p
										className="text-capitalize ps-4 py-0 my-0"
										data-tooltip-id="QCingCaptureInfo"
									>
										<i className="fa-regular fa-image  me-2"></i>
										{!currentCapture ? (
											<Skeleton width={80} />
										) : (
											`${currentCapture.id} - ${currentCapture.model_name}`
										)}
									</p>
									<Tooltip id="QCingCaptureInfo" place="top" effect="solid">
										Capture id - Sensor
									</Tooltip>
								</div>
							</Row>
						</div>
						<div className="col-12 col-md-4 d-flex justify-content-center">
							<Row>
								<div className="d-flex justify-content-end">
									<p
										className="text-capitalize ps-4 py-0 my-0"
										data-tooltip-id="QCingCaptureDate"
									>
										<i class="fa-solid fa-calendar-day me-2"></i>
										{!currentCapture ? (
											<Skeleton width={80} />
										) : (
											`${formatDate(currentCapture.captured.slice(0, 10))}`
										)}
									</p>
									{/* <p className="ms-2">
										<a href={sentinelUrl} target="_blank">
											Sentinel Hub
										</a>
									</p> */}
									<Tooltip id="QCingCaptureDate" place="top" effect="solid">
										Capture date
									</Tooltip>
								</div>
							</Row>
						</div>
					</div>

					<div className="row">
						<div
							className="col-12 py-3 py-md-1 col-lg-6"
							//style={{border: 'solid red 1px'}}
						>
							{!imageData2 ? (
								<div
									style={{
										width: '100%',
										height: '100%'
										//border: 'solid orange 1px'
									}}
								>
									<Skeleton height={'82vh'} data-tooltip-id="imageTrueColour" />
								</div>
							) : (
								<div
									style={{
										width: '100%',
										height: '85vh'
										//border: 'solid yellow 1px'
									}}
									//data-bs-toggle="modal"
									//data-bs-target="#id1"
									onClick={() => setModalOpen(true)}
									//data-tooltip-id="imageTrueColour"
								>
									<QCImageStockpiles
										imageData={imageData2}
										modalId={'id4'}
										currentCapture={currentCapture}
										setDrawnItemsValid={setDrawnItemsValid}
										stockpilesGeojsons={stockpilesGeojsons}
										setStockpilesGeojsons={setStockpilesGeojsons}
										stockpilesCase={stockpilesCase}
										activeStockpile={activeStockpile}
										setActiveStockpile={setActiveStockpile}
										selectedMapView={selectedMapView}
									/>
								</div>
							)}
						</div>

						<div className="col-12 py-3 py-md-1 col-lg-6">
							<div
								className="row d-flex"
								style={{
									height: '40vh'
									//border: 'solid pink 1px'
								}}
							>
								<div className="col">
									<PixelCountPlot
										pixelPlotData={pixelPlotData2}
										pixelPlotError={errorPixelPlotData}
										pixelPlotLoading={loadingPixelPlotData}
									/>
								</div>
							</div>
							<div
								style={{
									height: '43vh'
									// border: 'solid black 1px'
								}}
								className="row d-flex align-items-end"
							>
								{stockpilesCase === 2 && (
									<div className="col-7">
										<StockpilesGeojsonsList
											stockpilesGeojsons={stockpilesGeojsons}
											activeStockpile={activeStockpile}
											setActiveStockpile={setActiveStockpile}
											selectedMapView={selectedMapView}
											setSelectedMapView={setSelectedMapView}
										/>
									</div>
								)}
								<div
									className={
										stockpilesCase === 1
											? 'col pt-2 d-flex align-items-end justify-content-end '
											: 'col-5 order-1 pt-2 d-flex align-items-end justify-content-end '
									}
									style={{
										height: '100%'
										//border: 'solid green 1px'
									}}
								>
									<QCingFormStockpiles
										onStockpilesQCSubmit={onStockpilesQCSubmit}
										currentCapture={currentCapture}
										imageData={imageData2}
										skipCapture={skipCapture}
										drawnItemsValid={drawnItemsValid}
										stockpilesFormValue={stockpilesFormValue}
										setStockpilesFormValue={setStockpilesFormValue}
										stockpilesCase={stockpilesCase}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	} else if (pipelineContextQCing.comm === 'aircraft') {
		return (
			<>
				{/* <div style={{ paddingX: '20px' }}>
					<hr></hr>
					<div className="row px-0 px-md-5 pb-2">
						<div className="col-12 col-md-4 d-flex justify-content-center">
							<p className="py-0 my-0" data-tooltip-id="QCingSiteInfo">
								<i class="fa-solid fa-location-dot me-2"></i>
								{!currentCapture ? (
									<Skeleton width={80} />
								) : (
									`${currentCapture.site_id} - ${currentCapture.site_name}`
								)}
							</p>
							<Tooltip id="QCingSiteInfo" place="top" effect="solid">
								Site id - Site name
							</Tooltip>
						</div>
						<div className="col-12 col-md-4 d-flex justify-content-center">
							<Row>
								<div className="d-flex justify-content-end">
									<p
										className="text-capitalize ps-4 py-0 my-0"
										data-tooltip-id="QCingCaptureInfo"
									>
										<i className="fa-regular fa-image  me-2"></i>
										{!currentCapture ? (
											<Skeleton width={80} />
										) : (
											`${currentCapture.id} - ${currentCapture.model_name}`
										)}
									</p>
									<Tooltip id="QCingCaptureInfo" place="top" effect="solid">
										Capture id - Sensor
									</Tooltip>
								</div>
							</Row>
						</div>
						<div className="col-12 col-md-4 d-flex justify-content-center">
							<Row>
								<div className="d-flex justify-content-end">
									<p
										className="text-capitalize ps-4 py-0 my-0"
										data-tooltip-id="QCingCaptureDate"
									>
										<i class="fa-solid fa-calendar-day me-2"></i>
										{!currentCapture ? (
											<Skeleton width={80} />
										) : (
											`${formatDate(currentCapture.captured.slice(0, 10))}`
										)}
									</p>
									{/* <p className="ms-2">
										<a href={sentinelUrl} target="_blank">
											Sentinel Hub
										</a>
									</p> 
									<Tooltip id="QCingCaptureDate" place="top" effect="solid">
										Capture date
									</Tooltip>
								</div>
							</Row>
						</div>
					</div>
					<div className="row">
						<div
							className="col-12 py-3 py-md-1 col-lg-6"
							//style={{border: 'solid red 1px'}}
						>
							{!imageData2 ? (
								<div
									style={{
										width: '100%',
										height: '100%'
										//border: 'solid orange 1px'
									}}
								>
									<Skeleton height={'82vh'} data-tooltip-id="imageTrueColour" />
								</div>
							) : (
								<div
									style={{
										width: '100%',
										height: '85vh'
										//border: 'solid yellow 1px'
									}}
									//data-bs-toggle="modal"
									//data-bs-target="#id1"
									onClick={() => setModalOpen(true)}
									//data-tooltip-id="imageTrueColour"
								>
									<QCImage
										modalId={'id4'}
										stockpilesGeojsons={stockpilesGeojsons}
										setStockpilesGeojsons={setStockpilesGeojsons}
										imageUrl={imageData2.true_color}
										geojson={imageData2.aoi}
										perm_regions={imageData2.perm_regions}
										hotspots={imageData2.hotspots}
										bbox={imageData2.bbox}
										imageData={imageData2}
										setEditedGeojson={setEditedGeojson}
										openModal={modalOpen}
										featureCollection={featureCollection}
										setFeatureCollection={setFeatureCollection}
										featureLayers={featureLayers}
										setFeatureLayers={setFeatureLayers}
										currentCapture={currentCapture}
										setDrawnItemsValid={setDrawnItemsValid}
										drawnItemsValid={drawnItemsValid}
										stockpilesCase={stockpilesCase}
										activeStockpile={activeStockpile}
										setActiveStockpile={setActiveStockpile}
										selectedMapView={selectedMapView}
										setSelectedMapView={setSelectedMapView}
									/>
								</div>
							)}
						</div>
						<div className="col-12 py-3 py-md-1 col-lg-6">
							<div
								className="row d-flex"
								style={{
									height: '40vh'
									//border: 'solid pink 1px'
								}}
							>
								<div className="col">
									<PixelCountPlot
										pixelPlotData={pixelPlotData2}
										pixelPlotError={errorPixelPlotData}
										pixelPlotLoading={loadingPixelPlotData}
									/>
								</div>
							</div>
							<div
								style={{ height: '43vh',
								// border: 'solid black 1px'
							 }}
								className="row d-flex align-items-end"
							>
								{stockpilesCase === 2 && (
									<div className="col-7">
										<StockpilesGeojsonsList
											stockpilesGeojsons={stockpilesGeojsons}
											activeStockpile={activeStockpile}
											setActiveStockpile={setActiveStockpile}
											selectedMapView={selectedMapView}
											setSelectedMapView={setSelectedMapView}
										/>
									</div>
								)}
								<div
									className={
										stockpilesCase === 1
											? 'col pt-2 d-flex align-items-end justify-content-end '
											: 'col-5 order-1 pt-2 d-flex align-items-end justify-content-end '
									}
									style={{ height: '100%',
									 //border: 'solid green 1px'
									 }}
								>
									<QCingFormStockpiles
										onStockpilesQCSubmit={onStockpilesQCSubmit}
										currentCapture={currentCapture}
										imageData={imageData2}
										skipCapture={skipCapture}
										drawnItemsValid={drawnItemsValid}
										stockpilesFormValue={stockpilesFormValue}
										setStockpilesFormValue={setStockpilesFormValue}
										stockpilesCase={stockpilesCase}
									/>
								</div>
							</div>
						</div>
					</div>
				</div> */}
			</>
		)
	}
};

export default QCing;
